import request from "@/utils/http";

export function getOrderInfo2(params = {}) {
    return request({
        url: '/Claims/getOrderInfo2',
        method: 'post',
        data: params
    })
}
export function setOrderClaims(params = {}) {
    return request({
        url: '/Claims/setOrderClaims',
        method: 'post',
        data: params
    })
}
export function damageConfig() {
    return request({
        url: '/Claims/damageConfig',
        method: 'get'
    })
}
export function insService(instype_id,order_id) {
    return request({
        url: '/Claims/insService?instype_id='+instype_id+'&order_id='+order_id,
        method: 'get'
    })
}

export function applePrice(params = {}) {
    return request({
        url: '/Claims/applePrice',
        method: 'post',
        data: params,
        toast:false
    })
}
export function appleUpPrice(params = {}) {
    return request({
        url: '/Claims/appleUpPrice',
        method: 'post',
        data: params,
        toast:false
    })
}


export function editOrderClaims(params = {}) {
    return request({
        url: '/Claims/editOrderClaims',
        method: 'post',
        data: params
    })
}

export function getOrderClaimsInfo(claims_id) {
    return request({
        url: '/Claims/getOrderClaimsInfo?claims_id='+claims_id,
        method: 'get'
    })
}

export function checkOrder(type,imei) {
    return request({
        url: '/Claims/checkOrder?type='+type+'&imei='+imei,
        method: 'get'
    })
}

export function damageConfig2() {
    return request({
        url: '/Claims/damageConfig2',
        method: 'get'
    })
}

export function damageConfig3() {
    return request({
        url: '/Claims/damageConfig3',
        method: 'get'
    })
}

export function accountGear(params = {}) {
    return request({
        url: '/Claims/accountGear',
        method: 'post',
        data: params
    })
}

export function spbService(params = {}) {
    return request({
        url: '/Claims/spbService',
        method: 'get',
        params
    })
}

export function yanPrice(params = {}) {
    return request({
        url: '/Claims/yanPrice',
        method: 'get',
        params,
        toast:false
    })
}

export function spbService2() {
    return request({
        url: '/Claims/spbService2',
        method: 'get'
    })
}
export function spbService3() {
    return request({
        url: '/Claims/spbService3',
        method: 'get'
})
}

export function nnhxService(type) {
    return request({
        url: '/Claims/nnhxService?type='+type,
        method: 'get'
    })
}


export function anPriceNnhx(params = {}) {
    return request({
        url: '/Claims/anPriceNnhx',
        method: 'post',
        data: params
    })
}

export function applePriceNnhx(params = {}) {
    return request({
        url: '/Claims/applePriceNnhx',
        method: 'post',
        data: params
    })
}

export function getOrderList(params = {}) {
    return request({
        url: '/Claims/getOrderList',
        method: 'post',
        data: params
    })
}
export function anPriceZx(params = {}) {
    return request({
        url: '/Claims/anPriceZx',
        method: 'post',
        data: params
    })
}

