<template>
  <div>
    <!-- 搜索，扫码 -->
    <div class="input_btn">
      <van-search
        v-model="SerachValue"
        placeholder="请输入电话号码或IMEI码或扫码查询"
        @search="onSearch"
        show-action
        shape="round"
      >
        <template #right-icon>
          <div class="img_seach g-flex-jb">
            <!-- <img @click="smClick" src="../assets/images/home/icon_sm.png" alt=""> -->
            <scan-code
              @change="onSearch"
              :just-icon="true"
              v-model="SerachValue"
            ></scan-code>
          </div>
        </template>
        <template #action>
          <div @click="onSearch" class="search-btn">搜索</div>
        </template>
      </van-search>
    </div>

    <div v-if="list.length" class="Home_order">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getDataList"
        offset="0"
      >
        <div class="orderItem" v-for="iitm in list" :key="iitm.id">
          <div>
            <div class="item_top g-flex-jb g-flex-ac">
              <div class="item_tit_ri fs-28" @click="ItemDeteil(iitm)">
                申请履约>
              </div>
            </div>
            <div class="mt-30 g-flex-ac">
              <div class="ml-30 fs-28 left_wid" style="color: #999">
                权益类型:
              </div>
              <div class="fs-28" style="color: #333">
                {{ iitm.instype_name }}
              </div>
            </div>
            <div class="mt-30 g-flex-ac">
              <div class="ml-30 fs-28 left_wid" style="color: #999">
                参保人:
              </div>
              <div class="fs-28" style="color: #333">{{ iitm.name }}</div>
            </div>
            <div class="mt-30 g-flex-ac">
              <div class="ml-30 fs-28 left_wid" style="color: #999">
                联系电话:
              </div>
              <div class="fs-28" style="color: #333">{{ iitm.phone }}</div>
            </div>
            <div class="mt-30 g-flex-ac">
              <div class="ml-30 fs-28 left_wid" style="color: #999">IMEI:</div>
              <div class="fs-28" style="color: #333">{{ iitm.imei }}</div>
            </div>
            <div class="bot_img g-flex-dft">
              <div class="img_left">
                <img :src="iitm.brand_img" alt="" />
              </div>
              <div class="img_right fs-28 mt-20 ml-20">
                {{ iitm.brand }} {{ iitm.model_name }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div v-else class="Home_order_No g-flex-ac">
      <div class="order_No m-at">
        <img src="../../assets/images/home/zu8.png" alt="" />
        <span class="text fs-24">暂无内容</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderList, getOrderInfo2 } from "@/api/claimSettlement";
export default {
  name: "apply",
  props: {
    type: String,
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      params: {
        keyword: "",
        page: 1,
        limit: 5,
      },
      types: [
        { id: 1, name: "brokenScreen" },
        { id: 3, name: "iphone" },
        { id: 2, name: "android" },
        { id: 4, name: "androidYearChange" },
        { id: 4, name: "iphoneYearChange" },
        { id: 5, name: "smartChoice" },
        { id: 6, name: "huawei" },
        { id: 7, name: "iphoneSmart" },
        { id: 8, name: "yanbaofuwu" },
        { id: 9, name: "dianchifuwu" },
        { id: 10, name: "upServe" },
        { id: 11, name: "houdun" },
        { id: 13, name: "zuansu" },
      ],

      //  todo 新增保险要改的地方
      SerachValue: "",
      typeData: {},
    };
  },
  mounted() {
    this.getDataList(1);
  },
  methods: {
    // 查询
    getDataList(val) {
      if (val) {
        this.params.page = val;
        this.list = [];
      }
      getOrderList(this.params)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.finished = res.count.page >= res.count.pageAll;
          this.list = this.list.concat(res.data);
          // this.loading = false;
          this.params.page++;
          console.log(this.list);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 搜索按钮
    onSearch() {
      this.params.keyword = this.SerachValue;
      if (!this.SerachValue) {
        this.$toast("请先输入手机IMEI码");
        return;
      }
      this.getDataList(1);
    },
    // 查看详情
    ItemDeteil(item) {
      window.document.title = item.instype_name;
      this.types.map((ele) => {
        if (ele.id == item.instype_id) {
          this.typeData = ele.name;
          console.log(ele.name);
        }
      });
      this.$router.push({
        name: "ClaimSettlementApply",
        params: { type: this.typeData },
        query: { id: item.order_id, instype_id: item.instype_id },
      });
    },
  },
};
</script>

<style scoped lang="less">
.input_btn {
  margin: 20px auto 0;
  width: calc(100vw - 50px);
  height: 80px;
  border-radius: 40px;
  ::v-deep .van-search {
    padding: 0;
  }
  /deep/ .van-search__content {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img_seach {
    img {
      width: 40px;
      margin-right: 15px;
    }
  }
}
.search-btn {
  background-color: @base-color;
  height: 68px;
  border-radius: 34px;
  color: #fff;
  padding: 0 30px;
}

.Home_order {
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  .tit_top {
    margin-top: 70px;
    width: calc(100vw - 60px);
    height: 40px;
    background: linear-gradient(90deg, #e4ebf9 0%, #ffffff 78%);
    border-radius: 20px;
    .date {
      font-size: 66px;
      font-family: DIN;
      font-weight: bold;
      color: #434552;
      margin-bottom: 30px;
    }
    .mouth {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .right {
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .orderItem {
    margin-top: 30px;
    width: calc(100vw - 60px);
    //height: 520px;
    padding-bottom: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 21px 9px rgba(196, 197, 197, 0.4);
    border-radius: 20px;
    .bot_img {
      width: calc(100vw - 80px);
      margin: 20px auto 0;
      height: 150px;
      background: #f6f8fe;
      border-radius: 16px;
      .img_left {
        margin-left: 20px;
        width: 126px;
        height: 130px;
        img {
          margin-top: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .img_right {
        height: 27px;
        color: #333333;
      }
    }
    .left_wid {
      width: 150px;
      white-space: nowrap;
    }
    .item_top {
      height: 70px;
      width: 100%;
      border-bottom: 1px solid #f9f9f9;
      .item_tit_le {
        width: 180px;
        height: 72px;
        line-height: 72px;
      }
      .item_tit_ri {
        width: 148px;
        height: 72px;
        background: linear-gradient(
          -90deg,
          #e1f0fe 0%,
          rgba(225, 240, 254, 0) 100%
        );
        border-radius: 0px 20px 0px 0px;
        border-radius: 0px 20px 0px 0px;
        color: #0080ff;
        text-align: center;
        line-height: 72px;
      }
    }
  }
}
.Home_order_No {
  width: 100%;
  min-height: 500px;
  .order_No {
    width: 134px;
    text-align: center;
    img {
      width: 134px;
      height: 174px;
    }
    .text {
      color: #666;
    }
  }
}
.g-flex-jb {
  justify-content: flex-end !important;
}
</style>
